import dynamic from 'next/dynamic'

const DynamicAppWithNoSSR  = dynamic (
  () => import('./index') ,
  { ssr: false }
)

function App() {
  return (
    <DynamicAppWithNoSSR />
  )
}

export default App